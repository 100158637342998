import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsX } from 'react-icons/bs'

export interface Modal {
  title: JSX.Element | string
  className?: string
  hideCloseIcon?: boolean
  onClose?(): void
}

/**
 * Modal pop up
 *
 * @param warningMessage string
 * @param title string | JSX.Element
 * @param actionButtonOption FormButtonProps
 * @param className
 * @param onClose void
 */
export const useModal = (defaultVisibility = false) => {
  const [visibility, setVisibility] = useState(defaultVisibility)
  const { t } = useTranslation()

  // Open Modal
  const open = () => {
    setVisibility(true)
  }

  // Close Modal
  const close = () => {
    setVisibility(false)
  }

  const Modal = useCallback(
    ({
      title,
      className,
      hideCloseIcon,
      onClose,
      children
    }: React.PropsWithChildren<Modal>) => {
      return (
        <div
          className={`
          ${!visibility ? 'hidden' : ''}
          overflow-y-auto overflow-x-hidden flex flex-col items-center fixed top-0 right-0 left-0 md:inset-0 md:h-full`}
          style={{ zIndex: 100, backgroundColor: 'rgba(0,0,0,0.5)' }}
        >
          <div
            className={`
            relative w-[90vw] md:w-auto h-screen
            ${className}
          `}
          >
            <div className="relative bg-tertiary-10 px-10 py-5 top-[50%] translate-y-[-50%] rounded-lg shadow flex flex-col">
              <div
                className={`flex  ${
                  hideCloseIcon
                    ? 'justify-center'
                    : 'items-start  justify-between'
                }`}
              >
                <div className="text-xl md:text-2xl text-center font-semibold">
                  {title}
                </div>
                {!hideCloseIcon && (
                  <button onClick={() => { if (onClose) onClose(); close() }}>
                    <BsX color="#000" size={30} />
                  </button>
                )}
              </div>
              <div className="pt-5">{children}</div>
            </div>
          </div>
        </div>
      )
    },
    [visibility]
  )
  return { open, close, Modal }
}
