import React from 'react'
import * as Sentry from '@sentry/nextjs'
import { useTranslation } from 'react-i18next'
import Button from '../Button'
import environment from '@gruene-brise/data-access/lib/config/environment'

type ErrorBoundaryProps = {
  app: 'admin' | 'webshop'
}
const ErrorBoundary = ({
  children,
  app
}: React.PropsWithChildren<ErrorBoundaryProps>) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ eventId }) => (
        <ErrorBoundaryFallback app={app} eventId={eventId} />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

export const ErrorBoundaryFallback: React.FC<
  { eventId: string } & ErrorBoundaryProps
> = ({ eventId, app }) => {
  const { t } = useTranslation()
  const appBaseUrl =
    app === 'admin' ? environment.adminBaseUrl : environment.webshopBaseUrl
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center gap-8">
      <h1 className="text-primary text-2xl font-semibold">
        {t("It looks like we're having issues")}
      </h1>
      <div className="flex flex-col gap-8 items-center justify-center max-w-[500px]">
        <p className="text-primary-50 text-center">
          {t(
            'Our team has been notified. Please either refresh the page or return home to try again'
          )}
        </p>
        <div className="flex gap-1">
          <p className="text-primary-50">
            {t('If the issue continues, please')}
          </p>
          <button
            className="text-primary underline"
            onClick={() => Sentry.showReportDialog({ eventId })}
          >
            {t('get in touch')}
          </button>
        </div>
      </div>
      <Button
        className="!font-semibold"
        size="md"
        text={t('Go home')}
        variant="contained"
        brand="secondary"
        onClick={() => (window.location.href = appBaseUrl)}
      />
    </div>
  )
}

export default ErrorBoundary
